export const useScanScan = defineStore('scan', () => {
  /**
   * State
   */
  const closable = ref(false);
  const onClose: Ref<null | (() => void)> = ref(null);
  const absent: Ref<string[]> = ref([]);

  /**
   * Getters
   */
  const isAbsent = (id: string) => absent.value.includes(id);

  /**
   * Actions
   */
  const markAbsent = (id: string) => {
    absent.value = [id, ...absent.value];
    log('scan.mark-absent', 'Marked a child absent.', { child: id });
  };
  const markPresent = (id: string) => {
    absent.value = absent.value.filter((i) => i !== id);
    log('scan.mark-present', 'Marked a child present.', { child: id });
  };

  return {
    // State
    closable,
    onClose,

    // Getters
    isAbsent,

    // Actions
    markAbsent,
    markPresent,
  };
});
